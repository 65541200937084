import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import ProjectPageContainer from './ProjectPageContainer';
import HomePageContainer from './HomePageContainer'
import GifPageContainer from './GifPageContainer'
import DemoHomePageContainer from './DemoHomePageContainer'



const gifProjectIds=['709652615','452897519']
const ELEVEN_LABS_DEMO_PROJECT_ID="2144900467"


const HomePageWrapperContainer = () => {
  const { customerName, projectNameAndId } = useParams();
  const projectId = projectNameAndId.split('-').pop(); // Assuming projectNameAndId format is "name-id"
  if (projectId === ELEVEN_LABS_DEMO_PROJECT_ID) {
    return <DemoHomePageContainer />;
  } else if (gifProjectIds.includes(projectId)) {
    return <GifPageContainer />;
  } else {
    return <HomePageContainer />;
  }
  // if (projectId === ELEVEN_LABS_DEMO_PROJECT_ID) {
  //   return <DemoHomePageContainer />;
  // } else {
  //   return <HomePageContainer />;
  // }
};



const AppContainer = () => {


  return (
    <div id="app">
      <Router>
        <Routes>
          {/*<Route path="/:customerName/:projectNameAndId" element={<ProjectPageContainer />} />*/}
          {/*<Route path="/gif/:customerName/:projectNameAndId" element={<GifPageContainer />} />*/}
          <Route path="/:customerName/:projectNameAndId" element={<HomePageWrapperContainer />} />
          {/*<Route path="/" element={<HomePageContainer />} />*/}
        </Routes>
      </Router>
    </div>
  );
};

export default AppContainer;